import React, { useEffect, useRef, useState } from "react";
import MetaTags from "react-meta-tags";
import { AvForm } from "availity-reactstrap-validation";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { withRouter, Link, useHistory, useParams } from "react-router-dom";
import { Card, CardBody, Col, Container, Row, Spinner, Input } from "reactstrap";
import paginationFactory, {PaginationListStandalone,PaginationProvider,} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import SweetAlert from "react-bootstrap-sweetalert";
import {deleteMenuCategory, getAllRestaurants, getAllRestaurantsByManager, getCategoryMenuItems, getRestaurantById, getRestaurantMenuCategories, storeMenuItem, updateRestaurantById} from "../../store/actions";
import { useSelector, useDispatch } from "react-redux";
import AddMenuCategory from "./add-menu-category";
import { getUserRestaurantId } from "src/helpers/backend_helper";
import { hexColorToInt, intColorToHex } from "src/helpers/general";
import MenuItem from "./menu-item";
import { postFormData } from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";
import ImageGallery from "./image-gallery";


const colorPane = (color: any) => (
  <div style={{width: '-webkit-fill-available', height: '20px', backgroundColor: color ? intColorToHex(color) : '#000000'}}></div>
)

export const getMenuImageUrl = (backImage: any) => !!backImage && typeof(backImage) === 'string' && backImage.startsWith("id:") ? process.env.REACT_APP_API_URL + "/menu/images/" + backImage.replace("id:", "") : 
  typeof(backImage) === 'number' ? process.env.REACT_APP_API_URL + "/menu/images/" + backImage : backImage

const MenuCategory = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { categories, loading } = useSelector( (state: any) => state.menu);
  const { restaurants, singleRestaurant } = useSelector( (state: any) => state.restaurant);
  const [currentItem, setCurrentItem] = useState<any>([]);
  const [confirm_alert, setconfirm_alert] = useState(false);
  const [drawer, setDrawer] = useState<Boolean>(false);
  const [selectedRestId, setSelRestId] = useState(localStorage.selectedRestaurantId || '');
  const [selectedCategory, setSelectedCategory] = useState<any>(null);
  const [menuPublished, setMenuStatus] = useState(false);
  const [isHospitality, setHospitality] = useState(true);
  const formRef = useRef<any>();
  const [showCsvButton, setShowCsvButton] = useState(false);
  const [csvFile, setCsvFile] = useState<any>();
  const [fileRef, setFileRef] = useState<any>();
  const [clearBeforeCreate, setClearBeforeCreate] = useState(false);
  const [imageFiles, setImageFiles] = useState<any>();
  const imageUploadFormRef = useRef<any>();
  const [showImageGallery, setShowImageGallery] = useState(false);
  const [searchItemKey, setSearchItemKey] = useState("")

  useEffect(() => {
    const userRestId = getUserRestaurantId()
    if (userRestId > 0) {
      dispatch(getRestaurantById(userRestId));
      dispatch(getRestaurantMenuCategories(userRestId))
    } else if(userRestId == -1) {
      dispatch(getAllRestaurants());
    } else if(userRestId == 0){
      dispatch(getAllRestaurantsByManager())
    }
    if (formRef.current) {
      formRef.current.reset();
    }
    if (imageUploadFormRef.current) {
      imageUploadFormRef.current.reset();
    }
  }, []);

  useEffect(() => {
    if (getUserRestaurantId() > 0 && singleRestaurant && singleRestaurant.id) {
      setMenuStatus(singleRestaurant.MenuPublished);
      setHospitality(singleRestaurant.RestaurantType === 0 || singleRestaurant.RestaurantType === 2);
    }
  }, [singleRestaurant])

  useEffect(() => {
    if (selectedRestId !== '' && parseInt(selectedRestId) > 0) {
      localStorage.setItem("selectedRestaurantId", selectedRestId);
      dispatch(getRestaurantMenuCategories(selectedRestId))

      if (restaurants.length > 0) {
        const rests = restaurants.filter((r: any) => "" + r.id === selectedRestId)
        if (rests.length > 0 ) {
          setMenuStatus(rests[0].MenuPublished);
          setHospitality(rests[0].RestaurantType === 0 || rests[0].RestaurantType === 2);
        }
      }
    }
  }, [selectedRestId, restaurants])

  const { SearchBar } = Search;
  const pageOptions = {
    sizePerPage: 20,
    totalSize: categories.length, // replace later with size(companies),
    custom: true,
  };

  const getColor = (cat: any) => {
    const type = parseInt((cat.CategoryType || "0").toString());
    return type === 1 ? 'green' : type === 2 ? 'orange' : type === 3 ? 'rosybrown' : 'black';
  }

  const menuCategoryListColumns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      formatter: (cellContent: any, category: any) => (
        <span style={{color: getColor(category)}}>{cellContent}</span>
      )
    },
    {
      dataField: "Name",
      text: "Name",
      sort: true,
      formatter: (cellContent: any, category: any) => (
        <span style={{color: getColor(category)}}>{cellContent}</span>
      )
    },
    {
      dataField: "BackImage",
      text: "Background image",
      sort: true,
      formatter: (cellContent: any, category: any) => (
        <img src={getMenuImageUrl(cellContent)} width={40} height={40} />
      )
    },
    {
      dataField: "Search",
      text: "Search menu items",
      sort: true,
      formatter: (cellContent: any, category: any) => (
        <input type="text" onChange={(e: any) => onSearchMenuItems(e)} />
      )
    },
    {
      dataField: "",
      text: "Action",
      formatter: (cellContent: any, category: any) => (
        <div className="d-flex gap-3">
          <Link className="text-success" to="#">
            <i
              className="mdi mdi-pencil font-size-18"
              id="edittooltip"
              onClick={() => handleEditCategory(category)}
            ></i>
          </Link>

          <Link className="text-danger" to="#">
            <i
              className="mdi mdi-delete font-size-18"
              id="deletetooltip"
              onClick={() => handleDeleteCategory(category)}
            ></i>
          </Link>
        </div>
      ),
    },
  ];

  const onRestaurantChange = (event: any) => {
    setSelRestId(event.target.value)
  }

  const onSearchMenuItems = (e: any) => {
    setSearchItemKey(e.target.value)
  }

  const onChangeRestaurantType = (type: number) => {
    let restId = getUserRestaurantId() > 0 ? getUserRestaurantId() : selectedRestId
    let restaurant = getUserRestaurantId() > 0 ? singleRestaurant : restaurants.filter((r: any) => "" + r.id === selectedRestId)[0]
    if (restId > 0 && restaurant) {
      dispatch(updateRestaurantById(restId, {...restaurant, RestaurantType: type}, null, getUserRestaurantId() <= 0))
      setHospitality(type === 0)
    }
  }

  const handleEditCategory = (restaurant: any) => {
    history.push(`/menu/categories/${restaurant.id}`);
  };

  const handleDeleteCategory = (admin: any) => {
    setCurrentItem(admin);
    setconfirm_alert(true);
  };

  const confirmDelete = () => {
    setconfirm_alert(false);
    dispatch(deleteMenuCategory(currentItem.id));
  };

  const handleAddNewCategory = () => {
    setDrawer(true);
  };

  const handleUploadCSV = () => {
    const restId = getUserRestaurantId() > 0 ? getUserRestaurantId() : selectedRestId;
    if (restId > 0) {
      const formData = new FormData();
      formData.append("menuItems", csvFile);
      postFormData(`${url.UPLOAD_MENU_DATA}/${restId}?${clearBeforeCreate?'clearBeforeCreate=1':''}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(() => {
        fileRef.value = null;
        setShowCsvButton(false);
        dispatch(getRestaurantMenuCategories(restId));
      })
    }
  }

  const handleUploadImages = () => {
    const restId = getUserRestaurantId() > 0 ? getUserRestaurantId() : selectedRestId;
    if (restId > 0) {
      const formData = new FormData();
      for(let i=0; i<imageFiles.length; i++) {
        formData.append("images", imageFiles[i]);
      }
      postFormData(`${url.BATCH_ADD_MENU_IMAGE}/${restId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(() => {
        // fileRef.value = null;
        setShowCsvButton(false);
        setShowImageGallery(true);
      })
    }
  }

  const handleMenuPublish = () => {
    const id = getUserRestaurantId() > 0 ? getUserRestaurantId() : selectedRestId
    dispatch(updateRestaurantById(id, {MenuPublished: menuPublished ? 0 : 1}, null, getUserRestaurantId() <= 0))
    setMenuStatus(!menuPublished)
  }

  const onDrawerClose = (value: Boolean) => {
    setDrawer(false);
  };

  const onExpandRow = (row: any, isExpand: boolean) => {
    if (isExpand) {
      setSelectedCategory(row);
      dispatch(getCategoryMenuItems(row.id));
      setSearchItemKey('');
    }
  }
  
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title> Menu categories </title>
        </MetaTags>
        <Container fluid>
          
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          data={categories}
                          columns={menuCategoryListColumns}
                          bootstrap4
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <div className="row align-item ms-center">
                                  <div className="col-md-4">
                                    <div className="mb-3">
                                      <h5 className="card-title">
                                        Menu Category List{" "}
                                        <span className="text-muted fw-normal ms-2">
                                          ({categories.length})
                                        </span>
                                        <span className="fw-normal ms-2" style={{fontStyle: 'italic'}}>
                                        {menuPublished ? 'Published' : 'Draft' }
                                        </span>
                                      </h5>
                                    </div>
                                  </div>

                                  <div className="col-md-8">
                                    <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                                      
                                        <div className="mx-4">
                                          <span className={`toggle ${isHospitality ? "toggle-hp" : "toggle-rt"} toggle-on`}>{isHospitality ? "Hospitality" : "Retail"}</span>
                                        </div>
                                              
                                        {/* {!isHospitality && (
                                          <div>
                                            <Link
                                              to={showCsvButton ? "/files/menu-template.csv" : "#"}
                                              target={showCsvButton ? "_blank" : ""}
                                              className="btn btn-light"
                                              onClick={() => setTimeout(() => setShowCsvButton(!showCsvButton), 100)}
                                            >
                                            {showCsvButton ? "Download CSV template" : "CSV"}
                                            </Link>
                                          </div>
                                        )} */}
                                        <div>
                                        {!isHospitality && (
                                          <>
                                            <AvForm
                                              ref={formRef}
                                              onValidSubmit={(e: any, values: any) => {
                                                handleUploadCSV();
                                              }}
                                            >
                                              <div className="d-flex flex-row justify-space-between align-items-center mx-1">
                                                <input
                                                  id="menu-category-upload-csv-file"
                                                  name="menuItems"
                                                  type="file"
                                                  style={{display: "none"}}
                                                  onChange={(e: any) => {
                                                    setCsvFile(e.target.files[0])
                                                    setFileRef(e.target)
                                                    setTimeout(() => {
                                                      formRef.current.submit()
                                                    }, 100);
                                                  } }
                                                />
                                              </div>
                                            </AvForm>
                                            <AvForm
                                              ref={imageUploadFormRef}
                                              onValidSubmit={(e: any, values: any) => {
                                                handleUploadImages();
                                              }}
                                            >
                                              <div className="d-flex flex-row justify-space-between align-items-center mx-1">
                                                <input
                                                  id="menu-upload-images-gallery"
                                                  name="menuItems"
                                                  type="file"
                                                  multiple
                                                  style={{display: "none"}}
                                                  onChange={(e: any) => {
                                                    setImageFiles(e.target.files)
                                                    // setFileRef(e.target)
                                                    setTimeout(() => {
                                                      imageUploadFormRef.current.submit()
                                                    }, 100);
                                                  } }
                                                />
                                              </div>
                                            </AvForm>
                                            <Dropdown
                                              isOpen={showCsvButton}
                                              toggle={() => setShowCsvButton(!showCsvButton)}
                                              className="d-inline-block"
                                            >
                                              <DropdownToggle
                                                className="btn btn-light"
                                                id="page-header-user-dropdown"
                                                tag="button"
                                              >
                                                CSV & Gallery
                                                <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
                                              </DropdownToggle>
                                              <DropdownMenu className="dropdown-menu-end">
                                                <DropdownItem>
                                                  <Link
                                                    to={"/files/menu-template.csv"}
                                                    target={"_blank"}
                                                  >
                                                  Download CSV template
                                                  </Link>
                                                </DropdownItem>
                                                <div className="dropdown-divider" />
                                                <DropdownItem onClick={() => {
                                                  setClearBeforeCreate(true);
                                                  document.getElementById("menu-category-upload-csv-file")?.click()
                                                }}>
                                                  Upload As New / Override
                                                </DropdownItem>
                                                <DropdownItem onClick={() => {
                                                  setClearBeforeCreate(false);
                                                  document.getElementById("menu-category-upload-csv-file")?.click()
                                                }}>
                                                  Add to Existing Products
                                                </DropdownItem>
                                                <div className="dropdown-divider" />
                                                <DropdownItem onClick={() => {
                                                  setShowImageGallery(true)
                                                }}>
                                                  Open gallery
                                                </DropdownItem>
                                                <DropdownItem onClick={() => {
                                                  document.getElementById("menu-upload-images-gallery")?.click()
                                                }}>
                                                  Upload images to gallery
                                                </DropdownItem>
                                              </DropdownMenu>
                                            </Dropdown>
                                          </>
                                        )}
                                        </div>
                                        <div>
                                          <Link
                                            to="#"
                                            className="btn btn-light"
                                            onClick={handleAddNewCategory}
                                          >
                                            <i className="bx bx-plus me-1"></i>{" "}
                                            Add New
                                          </Link>
                                        </div>
                                        <div>
                                          <Link
                                            to="#"
                                            className="btn btn-light"
                                            onClick={handleMenuPublish}
                                          >
                                            <i className="bx bx-check me-1"></i>{" "}
                                            {menuPublished ? 'Set as draft' : 'Publish'}
                                          </Link>
                                        </div>
                                    </div>
                                  </div>
                                </div>
                                <Col sm="3">
                                  <div className="search-box ms-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon-search" />
                                    </div>
                                  </div>
                                </Col>
                                <Col sm="2">
                                  {getUserRestaurantId() <= 0 && (
                                    <Input name="restaurantId" onChange={onRestaurantChange} value={selectedRestId} type="select">
                                      <option key="all" value="-1">Select restaurant</option>
                                      {restaurants.map((restaurant: any) => (
                                        <option key={restaurant.id} value={restaurant.id}>{restaurant.ShopName}</option>
                                      ))}
                                    </Input>
                                  )}
                                </Col>
                                
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                  {loading ? (
                                    <div
                                      className="d-flex justify-content-center align-items-center"
                                      style={{ height: "20vh" }}
                                    >
                                      <i className="bx bx-loader bx-spin align-middle me-2" style={{fontSize: 46}}></i>
                                    </div>
                                  ) : (
                                    <BootstrapTable
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                      classes={
                                        "table align-middle table-nowrap table-hover"
                                      }
                                      bordered={false}
                                      striped={false}
                                      expandRow={{
                                        renderer: (row: any, rowIdx: number) => (<MenuItem row={row} selectedCategory={selectedCategory} searchItemKey={searchItemKey} isHospitality={isHospitality} />),
                                        onlyOneExpanding: true,
                                        onExpand: (row, isExpand, rowIdx, e) => onExpandRow(row, isExpand),
                                        expanded: !selectedCategory ? [] : [selectedCategory.id]
                                      }}
                                    />
                                  )}
                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-end mb-2">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          {confirm_alert ? (
            <SweetAlert
              title="Are you sure?"
              warning
              showCancel
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={() => {
                confirmDelete();
              }}
              onCancel={() => setconfirm_alert(false)}
            >
              You won't be able to revert this!
            </SweetAlert>
          ) : null}
          <AddMenuCategory 
            open={drawer} onDrawerClose={onDrawerClose} 
            selectedRestId={selectedRestId} 
            noOfCategories={categories.length} 
            linkingCategories={categories.filter((c: any) => !!c.CategoryType && c.CategoryType > 0 && c.CategoryType < 5)}
            toppingIds={categories.filter((c: any) => !!c.ToppingGroupId).map((c: any) => c.ToppingGroupId)}
          /> 
          <ImageGallery 
            restaurantId={getUserRestaurantId() < 0 ? selectedRestId : getUserRestaurantId()} 
            open={showImageGallery} 
            onDrawerClose={() => setShowImageGallery(false)} 
            onSelect={(id: any) => {
              setShowImageGallery(false);
            }}
            editable={true}
          />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(MenuCategory);
