import { call, put, takeEvery } from "redux-saga/effects";
import { VatRatesTypes } from "./actionTypes";
import { IResponse } from "../../interfaces/general";
import {getAllVatRatesSuccess} from "./actions";

import { getAllVatRatesAsync} from "../../helpers/backend_helper";

function* getAllVatRates({ payload: restaurantId}: any) {
  try {
    const response: IResponse = yield call(getAllVatRatesAsync, restaurantId);
    if(response.success) {
      yield put(getAllVatRatesSuccess(response.result));
    } 
  } catch (error) {
    console.log(error)
  }
}

function* vatRateSaga() {
  yield takeEvery(VatRatesTypes.GET_ALL_VATRATES, getAllVatRates)
}

export default vatRateSaga;


