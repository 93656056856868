import { combineReducers } from "redux"
import Layout from "./layout/reducer";
import login from "./auth/login/reducer";
import register from "./auth/register/reducer";

import admin from './admin/reducer';
import license from './license/reducer';
import customer from './customer/reducer'
import order from './order/reducer'
import orderDetails from './orderDetails/reducer'
import restaurant from "./restaurant/reducer";
import stats from "./stats/reducer";
import sms from "./sms/reducer";
import menu from "./menu/reducer";
import menuItems from "./menuItems/reducer";
import subMenuItems from "./subMenuItems/reducer";
import menuToppings from "./menuToppings/reducer";
import menuDressings from "./menuDressings/reducer";
import menuInstructions from "./menuInstructions/reducer";
import reports from "./reports/reducer";
import employees from "./employee/reducer";
import printers from "./printers/reducer";
import cardTerminals from "./cardTerminals/reducer";
import giftCards from "./giftCards/reducer";
import kitchenDisplayScreens from "./kitchenDisplayScreens/reducer";
import suppliers from "./suppliers/reducer";
import purchaseOrders from "./purchaseOrders/reducer";
import migrations from "./migration/reducer";
import bookings from "./bookings/reducer";
import vatRate from "./vatRates/reducer";

const rootReducer = combineReducers({
  Layout,
  login,
  register,
  admin,
  license,
  customer,
  order,
  orderDetails,
  restaurant,
  stats,
  sms,
  menu,
  menuItems,
  subMenuItems,
  menuToppings,
  menuDressings,
  menuInstructions,
  reports,
  employees,
  printers,
  cardTerminals,
  giftCards,
  kitchenDisplayScreens,
  suppliers,
  purchaseOrders,
  migrations,
  bookings,
  vatRate
})

export default rootReducer
