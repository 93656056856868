import { call, put, takeEvery } from "redux-saga/effects";
import { MenuDressingsActionTypes } from "./actionTypes";
import { DeleteResponse, IResponse } from "../../interfaces/general";
import { getAllMenuDressingsSuccess, storeMenuDressingSuccess, getMenuDressingByIdSuccess, menuDressingApiCallError, deleteMenuDressingSuccess, updateMenuDressingByIdSuccess } from "./actions";

import { addMenuDressingAsync, deleteMenuDressingByIdAsync, getAllMenuDressingsAsync, getMenuDressingByIdAsnyc, updateMenuDressingByIdAsync } from "../../helpers/backend_helper";

function* getAllMenuDressings() {
  try {
    const response: IResponse = yield call(getAllMenuDressingsAsync);
    if(response.success) {
      yield put(getAllMenuDressingsSuccess(response.result.sort((a:any, b:any) => b.id-a.id)));
    } 
  } catch (error) {
    console.log(error)
    yield put(menuDressingApiCallError(error))
  }
}

function* updateMenuDressing({ payload: {id, item, history} }: any) {
  try {
    const response: IResponse = yield call(updateMenuDressingByIdAsync, id, item);
    if(response.success) {
      yield put(updateMenuDressingByIdSuccess(response.result));
      if (history) {
        history.push('/menu/dressings')
      }
    }
  } catch (error) {
    console.log(error)
    yield put(menuDressingApiCallError(error))
  }
}

function* addMenuDressing({ payload: menuDressing }: any) {
  try {
    const response: IResponse = yield call(addMenuDressingAsync, menuDressing);
    if(response.success) {
      yield put(storeMenuDressingSuccess(response.result));
    }
  } catch (error) {
    console.log(error)
    yield put(menuDressingApiCallError(error))
  }
}

function* getMenuDressingById({ payload: id}: any) {
  try {
    const response: IResponse = yield call(getMenuDressingByIdAsnyc, id);
    if(response.success) {
      yield put(getMenuDressingByIdSuccess(response.result));
    }
  } catch (error) {
    console.log(error)
    yield put(menuDressingApiCallError(error))
  }
}

function* deleteMenuDressing({ payload: id }: any) {
  try {
    const response: DeleteResponse = yield call(deleteMenuDressingByIdAsync, id);
    if(response.success) {
      yield put(deleteMenuDressingSuccess(id));
    }
  } catch (error) {
    console.log(error)
    yield put(menuDressingApiCallError(error))
  }
}

function* restaurantSaga() {
  yield takeEvery(MenuDressingsActionTypes.GET_ALL_MENU_DRESSINGS, getAllMenuDressings)
  yield takeEvery(MenuDressingsActionTypes.UPDATE_MENU_DRESSING_BY_ID, updateMenuDressing);
  yield takeEvery(MenuDressingsActionTypes.DELETE_MENU_DRESSING_BY_ID, deleteMenuDressing);
  yield takeEvery(MenuDressingsActionTypes.ADD_MENU_DRESSING, addMenuDressing)
  yield takeEvery(MenuDressingsActionTypes.GET_MENU_DRESSING_BY_ID, getMenuDressingById);
}

export default restaurantSaga;


