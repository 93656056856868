import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { withRouter } from "react-router-dom";
import ReactDrawer from "react-drawer";
import { Col, Input, Row} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { storeMenuDressing, getAllRestaurants, storePrinter, getPrinterById, getRestaurantMenuCategoriesWithItems, getRestaurantEmployees, storeBooking, getCategoryMenuItems } from "../../store/actions";
import { useSelector, useDispatch } from "react-redux";
import { SpinnerCircular } from "spinners-react";
import { hexColorToInt } from "src/helpers/general";
import { getUserRestaurantId } from "src/helpers/backend_helper";
import { get } from "src/helpers/api_helper";

const AddBooking = (props: any) => {
  const dispatch = useDispatch();

  const { employees } = useSelector((state: any) => state.employees);
  const { addingOrUpdating: loading, success } = useSelector((state: any) => state.bookings);
  const { categories: menuCategories } = useSelector((state: any) => state.menu);
  const { items: menuItems } = useSelector((state: any) => state.menuItems);
  const [customers, setCustomers] = useState<any>([]);
  const [mobileNumber, setMobNumber] = useState("");
  const [tableAreas, setTableAreas] = useState<any>([]);
  const [tables, setTables] = useState<any>([]);
  const [selectedTableArea, setSelTableArea] = useState("");
  const [selectedTime, setSelectedTime] = useState('');
  const [selectedMenuCategory, setSelMenuCategory] = useState("");

  useEffect(() => {
    if (props.open) {
      const userRestId = getUserRestaurantId()
      if (userRestId > 0) {
        dispatch(getRestaurantMenuCategoriesWithItems(userRestId));
      } else {
        if (props.selectedRestId != '') {
          dispatch(getRestaurantMenuCategoriesWithItems(props.selectedRestId));
        }
      }
    }
  }, [props.open, props.selectedRestId])

  useEffect(() => {    
    if (props.open) {
      if (props.restaurantType === 1) {
        dispatch(getRestaurantEmployees(props.selectedRestId));
      } else {
        get("/dinein/tableAreas/restaurant-id/" + props.selectedRestId).then((result) => {
          setTableAreas(result.result);
        })
      }
    }
  }, [props.open, props.restaurantType]);

  useEffect(() => {
    if (selectedTableArea) {
      get("/dinein/tables/area-id/" + selectedTableArea).then((result) => {
        setTables(result.result);
      })
    }
  }, [selectedTableArea])

  useEffect(() => {
    if (selectedMenuCategory) {
      dispatch(getCategoryMenuItems(selectedMenuCategory));
    }
  }, [selectedMenuCategory])

  useEffect(() => {
    if (success) {
      props.onDrawerClose(false);
    }
  }, [success]);

  useEffect(() => {
    if (mobileNumber != "") {
      get("/customers/search/" + props.selectedRestId + "?Mobile=" + mobileNumber).then((result: any) => {
        setCustomers(result.data);
      })
    }
  }, [mobileNumber])
  
  const handleAdd = (values: any) => {
    dispatch(storeBooking({
      ...values,      
      RestaurantId: getUserRestaurantId() < 0 ? parseInt(props.selectedRestId || '1') : getUserRestaurantId(),
      BookingDateTime: values.BookingDate + " " + values.BookingTime,
      BookingStatus: "New"
    }));
  };

  // Function to handle changes in the time input
  const handleTimeChange = (event: any) => {
    setSelectedTime(event.target.value);
  };

  // Function to generate time options in 15-minute intervals
  const generateTimeOptions = () => {
    const options = [""];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 15) {
        const formattedHour = hour.toString().padStart(2, '0');
        const formattedMinute = minute.toString().padStart(2, '0');
        options.push(`${formattedHour}:${formattedMinute}`);
      }
    }
    return options;
  };

  return (
    <React.Fragment>
      <div className="add-license">
        <MetaTags>
          <title>Add New Booking</title>
        </MetaTags>
      </div>
      <ReactDrawer
        open={props.open}
        position={"right"}
        onClose={props.onDrawerClose}
      >
        <div className="add-license-form" style={{ padding: 20, width: 'fit-content' }}>
          <AvForm
              onValidSubmit={(e: any, values: any) => {
                handleAdd(values);
              }}
            >
              <Row className="align-items-center">
                <Col md="6">
                  <div className="mb-3">
                    <AvField type="date" label="Booking date" name="BookingDate" validate={{required: {value: true}}}/>
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3 ml-3">
                    <AvField id="timeInput" label="time" type="select" name="BookingTime" validate={{required: {value: true}}} value={selectedTime} onChange={handleTimeChange}>
                      {generateTimeOptions().map((time) => (
                        <option key={time} value={time}>{time}</option>
                      ))}
                    </AvField>
                  </div>
                </Col>
              </Row>
              <Row className="align-items-center">
                <Col md="12">
                  <div className="mb-3">
                    <AvField type="text" label="Customer" placeholder="Type mobile number to search" name="Customer"
                      onChange={(e: any) => setMobNumber(e.target.value)}
                    />
                  </div>
                </Col>
              </Row>
              <Row className="align-items-center">
                <Col md="12">
                  <div className="mb-3">
                    <AvField type="select" name="CustomerId" validate={{required: {value: true}}}>
                      <option key="0" value=""> Select customer </option>
                      {customers.map((customer: any) => (
                        <option key={customer.id} value={customer.id}> {customer.First_Name} {customer.Last_Name} ({customer.Mobile})</option>
                      ))}
                    </AvField>
                  </div>
                </Col>
              </Row>
              {(props.restaurantType === 0 || props.restaurantType === 2) && (
                <Row className="align-items-center">
                  <Col md="12">
                    <div className="mb-3">
                      <AvField type="number" label="Guest count" name="GuestCount" validate={{required: {value: true}}}/>
                    </div>
                  </Col>
                </Row>
              )}
              {props.restaurantType === 1 && (
                <Row className="align-items-center">
                  <Col md="6">
                    <div className="mb-3">
                      <AvField label="Service / Product" type="select" name="menuCategory" value={selectedMenuCategory} onChange={(e: any) => setSelMenuCategory(e.target.value)} validate={{required: {value: true}}}>
                        <option key="0" value="0"> Select menu category</option>
                        {menuCategories.map((cat: any) => (
                          <option key={cat.id} value={cat.id}> {cat.Name} </option>
                        ))}
                      </AvField>
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-3">
                      <AvField label="item" type="select" name="menu_item_order" validate={{required: {value: true}}}>
                        <option key="0" value="0"> Select menu item</option>
                        {menuItems.map((mi: any) => (
                          <option key={mi.id} value={mi.id}> {mi.Name} </option>
                        ))}
                      </AvField>
                    </div>
                  </Col>
                </Row>
              )}
              {props.restaurantType === 1 && (
                <Row className="align-items-center">
                  <Col md="12">
                    <div className="mb-3">
                      <AvField type="select" label="Employee" name="Employee" validate={{required: {value: true}}}>
                        <option key="0" value="0"> Select employee </option>
                        {employees.map((employee: any) => (
                          <option key={employee.id} value={employee.id}> {employee.First_Name} {employee.Last_Name} </option>
                        ))}
                      </AvField>
                    </div>
                  </Col>
                </Row>
              )}
              {(props.restaurantType === 0 || props.restaurantType === 2) && (
                <Row className="align-items-center">
                  <Col md="6">
                    <div className="mb-3">
                      <AvField label="Table" type="select" name="TableArea" value={selectedTableArea} onChange={(e: any) => setSelTableArea(e.target.value)} validate={{required: {value: true}}}>
                        <option key="0" value="0"> Select table area</option>
                        {tableAreas.map((area: any) => (
                          <option key={area.id} value={area.id}> {area.Name} </option>
                        ))}
                      </AvField>
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-3">
                      <AvField label=".." type="select" name="TableId" validate={{required: {value: true}}}>
                        <option key="0" value="0"> Select table</option>
                        {tables.map((table: any) => (
                          <option key={table.id} value={table.id}> {table.Name} </option>
                        ))}
                      </AvField>
                    </div>
                  </Col>
                </Row>
              )}
              {/* <Row className="align-items-center">
                <Col md="12">
                  <div className="mb-3">
                    <AvField type="select" label="Booking status" name="BookingStatus">
                      <option key="0" value=""> Select status </option>
                      {["Customer arrived", "No show", "Completed", "Cancelled"].map((status: string) => (
                        <option key="0" value={status}> {status} </option>
                      ))}
                    </AvField>
                  </div>
                </Col>
              </Row> */}

              <Row>
                <Col>
                  <div className="text-end">
                    <button type="submit" className="btn btn-success save-user" disabled={loading}>
                      {loading ? (
                        <SpinnerCircular
                          size={30}
                          thickness={150}
                          speed={150}
                          color="#36ad47"
                          secondaryColor="rgba(0, 0, 0, 0.44)"
                        />
                      ) : (
                        <>Add</>
                      )}
                    </button>
                  </div>
                </Col>
              </Row>
          </AvForm>
        </div>
      </ReactDrawer>
    </React.Fragment>
  );
};

export default withRouter(AddBooking);
