import { call, put, takeEvery } from "redux-saga/effects";
import { GiftCardsActionTypes } from "./actionTypes";
import { DeleteResponse, IResponse } from "../../interfaces/general";
import { deleteGiftCardSuccess, getRestaurantGiftCardsSuccess, getGiftCardByIdSuccess, giftCardApiCallError, storeGiftCardSuccess, updateGiftCardByIdSuccess } from "./actions";

import { addGiftCardAsync, deleteGiftCardByIdAsync, getAllGiftCardsAsync, getGiftCardByIdAsync, updateGiftCardByIdAsync } from "../../helpers/backend_helper";

function* getRestaurantGiftCards({ payload: id}: any) {
  try {
    const response: IResponse = yield call(getAllGiftCardsAsync, id);
    if(response.success) {
      yield put(getRestaurantGiftCardsSuccess(response.result.sort((a:any, b:any) => a.id-b.id)));
    } 
  } catch (error) {
    console.log(error)
    yield put(giftCardApiCallError(error))
  }
}

function* updateGiftCard({ payload: {id, item, history} }: any) {
  try {
    const response: IResponse = yield call(updateGiftCardByIdAsync, id, item);
    if(response.success) {
      yield put(updateGiftCardByIdSuccess(response.result));
      if (history) {
        history.push('/giftCards')
      }
    }
  } catch (error) {
    console.log(error)
    yield put(giftCardApiCallError(error))
  }
}

function* addGiftCard({ payload: menuDressing }: any) {
  try {
    const response: IResponse = yield call(addGiftCardAsync, menuDressing);
    if(response.success) {
      yield put(storeGiftCardSuccess(response.result));
    }
  } catch (error) {
    console.log(error)
    yield put(giftCardApiCallError(error))
  }
}

function* getGiftCardById({ payload: id}: any) {
  try {
    const response: IResponse = yield call(getGiftCardByIdAsync, id);
    if(response.success) {
      yield put(getGiftCardByIdSuccess(response.result));
    }
  } catch (error) {
    console.log(error)
    yield put(giftCardApiCallError(error))
  }
}

function* deleteGiftCard({ payload: id }: any) {
  try {
    const response: DeleteResponse = yield call(deleteGiftCardByIdAsync, id);
    if(response.success) {
      yield put(deleteGiftCardSuccess(id));
    }
  } catch (error) {
    console.log(error)
    yield put(giftCardApiCallError(error))
  }
}

function* restaurantSaga() {
  yield takeEvery(GiftCardsActionTypes.GET_RESTAURANT_GIFT_CARDS, getRestaurantGiftCards)
  yield takeEvery(GiftCardsActionTypes.UPDATE_GIFT_CARD_BY_ID, updateGiftCard);
  yield takeEvery(GiftCardsActionTypes.DELETE_GIFT_CARD_BY_ID, deleteGiftCard);
  yield takeEvery(GiftCardsActionTypes.ADD_GIFT_CARD, addGiftCard)
  yield takeEvery(GiftCardsActionTypes.GET_GIFT_CARD_BY_ID, getGiftCardById);
}

export default restaurantSaga;


