import { call, put, takeEvery } from "redux-saga/effects";
import { MenuItemsActionTypes } from "./actionTypes";
import { DeleteResponse, IResponse } from "../../interfaces/general";
import {getAllMenuItemsSuccess, storeMenuItemSuccess, getMenuItemByIdSuccess, menuItemApiCallError, deleteMenuItemSuccess, updateMenuItemByIdSuccess, getCategoryMenuItemsSuccess, getSupplierMenuItemsSuccess} from "./actions";

import { addMenuItemAsync, deleteMenuItemByIdAsync, getAllMenuItemsAsync, getCategoryMenuItemsAsync, getSupplierMenuItemsAsync, getMenuItemByIdAsnyc, updateMenuItemByIdAsync, uploadSingleImageAsync } from "../../helpers/backend_helper";

function* getAllMenuItems() {
  try {
    const response: IResponse = yield call(getAllMenuItemsAsync);
    if(response.success) {
      yield put(getAllMenuItemsSuccess(response.result.sort((a:any, b:any) => b.id-a.id)));
    } 
  } catch (error) {
    console.log(error)
    yield put(menuItemApiCallError(error))
  }
}

function* getCategoryMenuItems({ payload: id}: any) {
  try {
    const response: IResponse = yield call(getCategoryMenuItemsAsync, id);
    if(response.success) {
      yield put(getCategoryMenuItemsSuccess(response.result));
    }
  } catch (error) {
    console.log(error)
    yield put(menuItemApiCallError(error))
  }
}

function* getSupplierMenuItems({ payload: id}: any) {
  try {
    const response: IResponse = yield call(getSupplierMenuItemsAsync, id);
    if(response.success) {
      yield put(getSupplierMenuItemsSuccess(response.result));
    }
  } catch (error) {
    console.log(error)
    yield put(menuItemApiCallError(error))
  }
}

function* updateMenuItem({ payload: {id, item, history} }: any) {
  try {
    if (!!item.BackImage) {
      if (typeof(item.BackImage) === 'string' && item.BackImage.startsWith("id:")) {
        item.BackImage = parseInt(item.BackImage.replace("id:", ""))
      } else {
        const formData = new FormData();
        formData.append("images", item.BackImage);
        const imgRes: IResponse = yield call(uploadSingleImageAsync, formData, item.RestaurantId);
        item.BackImage = imgRes.result[0]?.id;
      }
    }
    const response: IResponse = yield call(updateMenuItemByIdAsync, id, item);
    if(response.success) {
      yield put(updateMenuItemByIdSuccess(response.result));
      if (history) {
        history.push('/menu/categories')
      }
    }
  } catch (error) {
    console.log(error)
    yield put(menuItemApiCallError(error))
  }
}

function* addMenuItem({ payload: menuItem }: any) {
  try {
    const response: IResponse = yield call(addMenuItemAsync, menuItem);
    if(response.success) {
      yield put(storeMenuItemSuccess(response.result));
    }
  } catch (error) {
    console.log(error)
    yield put(menuItemApiCallError(error))
  }
}

function* getMenuItemById({ payload: id}: any) {
  try {
    const response: IResponse = yield call(getMenuItemByIdAsnyc, id);
    if(response.success) {
      yield put(getMenuItemByIdSuccess(response.result));
    }
  } catch (error) {
    console.log(error)
    yield put(menuItemApiCallError(error))
  }
}

function* deleteMenuItem({ payload: id }: any) {
  try {
    const response: DeleteResponse = yield call(deleteMenuItemByIdAsync, id);
    if(response.success) {
      yield put(deleteMenuItemSuccess(id));
    }
  } catch (error) {
    console.log(error)
    yield put(menuItemApiCallError(error))
  }
}

function* restaurantSaga() {
  yield takeEvery(MenuItemsActionTypes.GET_ALL_MENU_ITEMS, getAllMenuItems)
  yield takeEvery(MenuItemsActionTypes.GET_CATEGORY_MENU_ITEMS, getCategoryMenuItems)
  yield takeEvery(MenuItemsActionTypes.GET_SUPPLIER_MENU_ITEMS, getSupplierMenuItems)
  yield takeEvery(MenuItemsActionTypes.UPDATE_MENU_ITEM_BY_ID, updateMenuItem);
  yield takeEvery(MenuItemsActionTypes.DELETE_MENU_ITEM_BY_ID, deleteMenuItem);
  yield takeEvery(MenuItemsActionTypes.ADD_MENU_ITEM, addMenuItem)
  yield takeEvery(MenuItemsActionTypes.GET_MENU_ITEM_BY_ID, getMenuItemById);
}

export default restaurantSaga;


