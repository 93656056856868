import { call, put, takeEvery } from "redux-saga/effects";
import { KDScreenActionTypes } from "./actionTypes";
import { DeleteResponse, IResponse } from "../../interfaces/general";
import {getAllKDScreensSuccess, storeKDScreenSuccess, getRestaurantKDScreensSuccess, updateKDScreenByIdSuccess, getKDScreenByIdSuccess, kdScreenApiCallError, deleteKDScreenSuccess} from "./actions";

import { addKDScreenAsync, deleteKDScreenByIdAsync, getAllKDScreensAsync, getKDScreenByIdAsnyc, getRestaurantKDScreensAsync, updateKDScreenByIdAsync } from "../../helpers/backend_helper";

function* getAllKDScreens() {
  try {
    const response: IResponse = yield call(getAllKDScreensAsync);
    if(response.success) {
      yield put(getAllKDScreensSuccess(response.result.sort((a:any, b:any) => b.id-a.id)));
    } 
  } catch (error) {
    console.log(error)
    yield put(kdScreenApiCallError(error))
  }
}

function* getRestaurantKDScreens({ payload: id}: any) {
  try {
    const response: IResponse = yield call(getRestaurantKDScreensAsync, id);
    if(response.success) {
      yield put(getRestaurantKDScreensSuccess(response.result));
    }
  } catch (error) {
    console.log(error)
    yield put(kdScreenApiCallError(error))
  }
}

function* updateKDScreen({ payload: {id, category, history} }: any) {
  try {
    const response: IResponse = yield call(updateKDScreenByIdAsync, id, category);
    if(response.success) {
      yield put(updateKDScreenByIdSuccess(response.result));
      if (history) {
        history.push('/kitchenDisplayScreens')
      }
    }
  } catch (error) {
    console.log(error)
    yield put(kdScreenApiCallError(error))
  }
}

function* addKDScreen({ payload: KDScreen }: any) {
  try {
    const response: IResponse = yield call(addKDScreenAsync, KDScreen);
    if(response.success) {
      yield put(storeKDScreenSuccess(response.result));
    }
  } catch (error) {
    console.log(error)
    yield put(kdScreenApiCallError(error))
  }
}

function* getKDScreenById({ payload: id}: any) {
  try {
    const response: IResponse = yield call(getKDScreenByIdAsnyc, id);
    if(response.success) {
      yield put(getKDScreenByIdSuccess(response.result));
    }
  } catch (error) {
    console.log(error)
    yield put(kdScreenApiCallError(error))
  }
}

function* deleteKDScreen({ payload: id }: any) {
  try {
    const response: DeleteResponse = yield call(deleteKDScreenByIdAsync, id);
    if(response.success) {
      yield put(deleteKDScreenSuccess(id));
    }
  } catch (error) {
    console.log(error)
    yield put(kdScreenApiCallError(error))
  }
}

function* restaurantSaga() {
  yield takeEvery(KDScreenActionTypes.GET_ALL_KD_SCREENS, getAllKDScreens)
  yield takeEvery(KDScreenActionTypes.GET_RESTAURANT_KD_SCREENS, getRestaurantKDScreens)
  yield takeEvery(KDScreenActionTypes.UPDATE_KD_SCREEN_BY_ID, updateKDScreen);
  yield takeEvery(KDScreenActionTypes.DELETE_KD_SCREEN_BY_ID, deleteKDScreen);
  yield takeEvery(KDScreenActionTypes.ADD_KD_SCREEN, addKDScreen)
  yield takeEvery(KDScreenActionTypes.GET_KD_SCREEN_BY_ID, getKDScreenById);
}

export default restaurantSaga;


