import { call, put, takeEvery } from "redux-saga/effects";
import { ReportsActionTypes } from "./actionTypes";
import { IResponse } from "../../interfaces/general";
import { getAvgPrepTimeSuccess, getHourlySalesSuccess, getSalesPerMenuCategoriesSuccess, getSalesPerMenuItemsSuccess, getSalesPerPaymentTypesSuccess, getSalesPerSubMenuItemsSuccess, reportsApiCallError} from "./actions";

import { getAvgPrepTimeAsync, getHourlySalesByRestaurantIdAsync, getSalesPerMenuCategoriesAsync, getSalesPerMenuItemsAsync, getSalesPerPaymentTypesAsync, getSalesPerSubMenuItemsAsync } from "../../helpers/backend_helper";

function* getHourlySales({payload: {restaurantId, dateFrom, dateTo}}: any) {
  try {
    const response: IResponse = yield call(getHourlySalesByRestaurantIdAsync, restaurantId, dateFrom, dateTo);
    if(response.success) {
      yield put(getHourlySalesSuccess(response.result));
    } 
  } catch (error) {
    console.log(error)
    yield put(reportsApiCallError(error))
  }
}

function* getSalesPerMenuCategories({payload: {restaurantId, dateFrom, dateTo}}: any) {
  try {
    const response: IResponse = yield call(getSalesPerMenuCategoriesAsync, restaurantId, dateFrom, dateTo);
    if(response.success) {
      yield put(getSalesPerMenuCategoriesSuccess(response.result));
    } 
  } catch (error) {
    console.log(error)
    yield put(reportsApiCallError(error))
  }
}

function* getSalesPerMenuItems({payload: {restaurantId, categoryId, dateFrom, dateTo} }: any) {
  try {
    const response: IResponse = yield call(getSalesPerMenuItemsAsync, restaurantId, categoryId, dateFrom, dateTo);
    if(response.success) {
      yield put(getSalesPerMenuItemsSuccess(response.result));
    } 
  } catch (error) {
    console.log(error)
    yield put(reportsApiCallError(error))
  }
}

function* getSalesPerSubMenuItems({payload: {restaurantId, menuItemId, dateFrom, dateTo}}: any) {
  try {
    const response: IResponse = yield call(getSalesPerSubMenuItemsAsync, restaurantId, menuItemId, dateFrom, dateTo);
    if(response.success) {
      yield put(getSalesPerSubMenuItemsSuccess(response.result));
    } 
  } catch (error) {
    console.log(error)
    yield put(reportsApiCallError(error))
  }
}

function* getSalesPerPaymentTypes({payload: {restaurantId, dateFrom, dateTo}}: any) {
  try {
    const response: IResponse = yield call(getSalesPerPaymentTypesAsync, restaurantId, dateFrom, dateTo);
    if(response.success) {
      yield put(getSalesPerPaymentTypesSuccess(response.result));
    } 
  } catch (error) {
    console.log(error)
    yield put(reportsApiCallError(error))
  }
}

function* getAvgTime({payload: {restaurantId, dateFrom, dateTo}}: any) {
  try {
    const response: IResponse = yield call(getAvgPrepTimeAsync, restaurantId, dateFrom, dateTo);
    if(response.success) {
      yield put(getAvgPrepTimeSuccess(response.result.avgPrepTime));
    } 
  } catch (error) {
    console.log(error)
    yield put(reportsApiCallError(error))
  }
}

function* restaurantSaga() {
  yield takeEvery(ReportsActionTypes.GET_HOURLY_SALES, getHourlySales);
  yield takeEvery(ReportsActionTypes.GET_SALES_PER_MENU_CATEGORY, getSalesPerMenuCategories);
  yield takeEvery(ReportsActionTypes.GET_SALES_PER_MENU_ITEM, getSalesPerMenuItems);
  yield takeEvery(ReportsActionTypes.GET_SALES_PER_SUB_MENU_ITEM, getSalesPerSubMenuItems);
  yield takeEvery(ReportsActionTypes.GET_SALES_PER_PAYMENT_TYPES, getSalesPerPaymentTypes);
  yield takeEvery(ReportsActionTypes.GET_AVG_PREP_TIME, getAvgTime);
}

export default restaurantSaga;


