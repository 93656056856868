import React, { useEffect, useRef, useState } from "react";
import MetaTags from "react-meta-tags";
import { withRouter } from "react-router-dom";
import ReactDrawer from "react-drawer";
import { Col, Row, Label } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { addAdmin, getAllRestaurants } from "../../store/actions";
import { useSelector, useDispatch } from "react-redux";
import { SpinnerCircular } from "spinners-react";
import Select from 'react-select';
let options:any = [];

const AddAdmin = (props: any) => {
  const dispatch = useDispatch();
  const { success, loading } = useSelector((state: any) => state.admin);
  const { restaurants } = useSelector( (state: any) => state.restaurant);
  const [selectedRestaurants, setSelectedRestaurants] = useState([]);
  const [error, setError] = useState('');

  useEffect(()=>{
    dispatch(getAllRestaurants())
  },[])

  useEffect(()=>{
    restaurants.map((restaurant:any)=>{
      const option = {value:restaurant.id,label:restaurant.ShopName};
      options.push(option)
    })
  },[restaurants])

  useEffect(() => {
    if (success) {
      props.onDrawerClose(false);
    }
  }, [success]);

  const handleAddAdmin = (values: any) => {
    if (selectedRestaurants.length === 0) {
      setError('Please select at least one restaurant');
    } else {
      setError('');
      const RestaurantIds = selectedRestaurants.map((item:any)=>item.value);
      values.RestaurantId = RestaurantIds[0];
      values.firstname=values.name;
      if (RestaurantIds.length>1) {
        values.RestaurantIds = JSON.stringify(RestaurantIds);
      }
      dispatch(addAdmin(values));
    }
    
  };

  const handleChangeRestaurant = (selectedRestaurant:any)=>{
    setSelectedRestaurants(selectedRestaurant)
  }

  return (
    <React.Fragment>
      <div className="add-admin">
        <MetaTags>
          <title>Add New Admin</title>
        </MetaTags>
      </div>
      <ReactDrawer
        open={props.open}
        position={"right"}
        onClose={props.onDrawerClose}
      >
        <div className="add-admin-form" style={{ padding: 20 }}>
          <AvForm
            onValidSubmit={(e: any, values: any) => {
              handleAddAdmin(values);
            }}
          >
            <div className="add-admin-form-cotainer">
              <Row>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="name"
                      label="Name"
                      type="text"
                      errorMessage="Invalid Name"
                      validate={
                        {
                          required: { value: true },
                        }
                      }
                      value=""
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="username"
                      label="UserName"
                      type="text"
                      errorMessage="Invalid UserName"
                      validate={{
                        required: { value: true },
                      }}
                      value=""
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="email"
                      label="Email"
                      type="email"
                      errorMessage="Invalid email"
                      validate={{
                        required: { value: true },
                      }}
                      value=""
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="password"
                      label="Password"
                      type="password"
                      errorMessage="Invalid Password"
                      validate={{
                        required: { value: true },
                      }}
                      value=""
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md="6">
                  <div className="mb-3">
                    <Label>Restaurant</Label>
                    <Select
                      isMulti
                      options={options}
                      value={selectedRestaurants}
                      onChange={handleChangeRestaurant}
                    ></Select>
                    {error && <p>{error}</p>}
                  </div>
                </Col>
                <Col md="6">
                  <div className="text-end">
                    <button
                      type="submit"
                      className="btn btn-success save-user"
                      disabled={loading}
                    >
                      {loading ? (
                        <SpinnerCircular
                          size={30}
                          thickness={150}
                          speed={150}
                          color="#36ad47"
                          secondaryColor="rgba(0, 0, 0, 0.44)"
                        />
                      ) : (
                        <>Save</>
                      )}
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
          </AvForm>
        </div>
      </ReactDrawer>
    </React.Fragment>
  );
};

export default withRouter(AddAdmin);
