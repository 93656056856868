export const ADMIN_LOGIN = '/users/auth/login'
//admins
export const GET_ALL_ADMINS = '/users'

export const GET_ADMINS_BY_COMPANYCODE = '/admins/companyCode'
export const ADD_ADMIN = '/users/auth/signup'
export const GET_ADMIN_BY_ID = '/users'
export const UPDATE_ADMIN_BY_DOCID = '/users'
export const DEL_ADMIN_BY_DOCID = '/users'


//LICENSES
export const GET_ALL_LICENSES = '/license'
export const ADD_LICENSE = '/license/store'
export const GET_LICENSE_BY_ID = '/license'
export const UPDATE_LICENSE_BY_ID = '/license'
export const DEL_LICENSE_BY_ID = '/license'

//CUSTOMERS
export const GET_ALL_CUSTOMERS = '/customers'
export const GET_SINGLE_CUSTOMER = '/customers/single'
export const GET_RESTAURANT_CUSTOMERS = '/customers/restaurant'

//RESTAURANTS
export const GET_ALL_RESTAURANTS = '/restaurants'
export const GET_RESTAURANT_BY_ID = '/restaurants'
export const UPDATE_RESTAURANT_BY_DOCID = '/restaurants'
export const ADD_RESTAURANT = '/restaurants/store'

//Migrations
export const GET_ALL_MIGRATIONS = '/migrations/all'
export const GET_MIGRATION_BY_ID = '/migrations/single'
export const ADD_RESTAURANT_MIGRATIONS = '/migrations/store/restaurant-id'
export const DELETE_MIGRATION_BY_ID = '/migrations/single'

//Orders
export const GET_ALL_ORDERS = '/orders'
export const GET_ORDERS_PAGINATED = '/orders/filter/pagination'
export const GET_RESTAURANT_ORDERS_WITH_PAGINATION = '/orders/restaurant-id-with-pagination'
export const GET_TOTAL_SALES_TODAY = '/orders/totalsales/today'
export const GET_TOTAL_COUNTS_TODAY = '/orders/totalcounts/today'
export const GET_COUNTS_CASH = '/orders/totalcounts/paymentType/cash'
export const GET_COUNTS_CARD = '/orders/totalcounts/paymentType/card'
export const GET_COUNTS_DELIVERY = '/orders/totalcounts/ordertype/delivery'
export const GET_COUNTS_TAKEAWAY = '/orders/totalcounts/ordertype/takeaway'
export const GET_COUNTS_COLLECTION = '/orders/totalcounts/ordertype/collection'
export const GET_COUNTS_EATIN = '/orders/totalcounts/ordertype/eatin'
export const GET_TOTAL_WEBSITES_TODAY = '/orders/totalwebsites/today'
export const GET_AVG_PREP_TIME = '/orders/avgpreptime'

//Order Details
export const GET_ORDER_DETAIL_BY_ID = '/orders/details/single-id'

//Menus
export const GET_ALL_MENU_CATEGORIES = '/menu/categories'
export const GET_RESTAURANT_MENU_CATEGORIES = '/menu/categories/restaurant-id'
export const UPDATE_MENU_CATEGORY = '/menu/categories'
export const GET_MENU_CATEGORY_BY_ID = '/menu/categories'
export const ADD_MENU_CATEGORY = '/menu/categories/store'
export const DELETE_MENU_CATEGORY = '/menu/categories'
export const UPLOAD_MENU_DATA = '/menu/categories/upload-csv/restaurant-id'

//Menu images
export const ADD_MENU_IMAGE = '/menu/images/store'
export const BATCH_ADD_MENU_IMAGE = '/menu/images/batch'
export const GET_RESTAURANT_IMAGES = '/menu/images/restaurant-id'

//Menu items
export const GET_ALL_MENU_ITEMS = '/menu/items'
export const GET_CATEGORY_MENU_ITEMS = '/menu/items/menu-id'
export const GET_SUPPLIER_MENU_ITEMS = '/menu/items/supplier-id'
export const UPDATE_MENU_ITEM = '/menu/items'
export const GET_MENU_ITEM_BY_ID = '/menu/items'
export const ADD_MENU_ITEM = '/menu/items/store'
export const DELETE_MENU_ITEM = '/menu/items'

//Sub Menu items
export const GET_ALL_SUB_MENU_ITEMS = '/menu/subMenuItems'
export const GET_MENUITEM_SUBMENUITEMS = '/menu/subMenuItems/menu-item-id'
export const UPDATE_SUB_MENU_ITEM = '/menu/subMenuItems'
export const GET_SUB_MENU_ITEM_BY_ID = '/menu/subMenuItems'
export const ADD_SUB_MENU_ITEM = '/menu/subMenuItems/store'
export const DELETE_SUB_MENU_ITEM = '/menu/subMenuItems'

//Menu toppings
export const GET_ALL_MENU_TOPPINGS = '/menu/toppings'
export const UPDATE_MENU_TOPPING = '/menu/toppings'
export const GET_MENU_TOPPING_BY_ID = '/menu/toppings'
export const ADD_MENU_TOPPING = '/menu/toppings/store'
export const DELETE_MENU_TOPPING = '/menu/toppings'

//Menu dressings
export const GET_ALL_MENU_DRESSINGS = '/menu/dressings'
export const UPDATE_MENU_DRESSING = '/menu/dressings'
export const GET_MENU_DRESSING_BY_ID = '/menu/dressings'
export const ADD_MENU_DRESSING = '/menu/dressings/store'
export const DELETE_MENU_DRESSING = '/menu/dressings'

//Menu instructions
export const GET_ALL_MENU_INSTRUCTIONS = '/menu/instructions'
export const UPDATE_MENU_INSTRUCTION = '/menu/instructions'
export const GET_MENU_INSTRUCTION_BY_ID = '/menu/instructions'
export const ADD_MENU_INSTRUCTION = '/menu/instructions/store'
export const DELETE_MENU_INSTRUCTION = '/menu/instructions'

//Reports
export const GET_HOURLY_SALES = '/reports/hourlySales'
export const GET_SALES_PER_MENU_CATEGORIES = '/reports/salesPerMenuCategories'
export const GET_SALES_PER_PAYMENT_TYPES = '/reports/salesPerPaymentTypes'
export const GET_SALES_PER_MENU_ITEMS = '/reports/salesPerMenuItems'
export const GET_SALES_PER_SUB_MENU_ITEMS = '/reports/salesPerSubMenuItems'

//Employees
export const GET_ALL_EMPLOYEES = '/employees'
export const GET_RESTAURANT_EMPLOYEES = '/employees/restaurant-id'
export const GET_EMPLOYEE_BY_ID = '/employees/id'
export const UPDATE_EMPLOYEE = '/employees'
export const ADD_EMPLOYEE = '/employees'
export const DELETE_EMPLOYEE = '/employees'

//Printer
export const GET_RESTAURANT_PRINTERS = '/printers/restaurantId'
export const UPDATE_PRINTER_BY_ID = '/printers'
export const GET_PRINTER_BY_ID = '/printers'
export const ADD_PRINTER = '/printers/store'
export const DELETE_PRINTER_BY_ID = '/printers'

//Card terminal
export const GET_RESTAURANT_CARD_TERMINALS = '/cardTerminals/restaurantId'
export const UPDATE_CARD_TERMINAL_BY_ID = '/cardTerminals'
export const GET_CARD_TERMINAL_BY_ID = '/cardTerminals'
export const ADD_CARD_TERMINAL = '/cardTerminals/store'
export const DELETE_CARD_TERMINAL_BY_ID = '/cardTerminals'

//Gift card
export const GET_RESTAURANT_GIFT_CARDS = '/giftCards/restaurantId'
export const UPDATE_GIFT_CARD_BY_ID = '/giftCards'
export const GET_GIFT_CARD_BY_ID = '/giftCards'
export const ADD_GIFT_CARD = '/giftCards/store'
export const DELETE_GIFT_CARD_BY_ID = '/giftCards'

//Kitchen Display Screens
export const GET_ALL_KD_SCREENS = '/kitchenDisplayScreens'
export const GET_RESTAURANT_KD_SCREENS = '/kitchenDisplayScreens/restaurant-id'
export const UPDATE_KD_SCREEN = '/kitchenDisplayScreens'
export const GET_KD_SCREEN_BY_ID = '/kitchenDisplayScreens'
export const ADD_KD_SCREEN = '/kitchenDisplayScreens/store'
export const DELETE_KD_SCREEN = '/kitchenDisplayScreens'

//Suppliers
export const GET_RESTAURANT_SUPPLIERS = '/suppliers/restaurantId'
export const UPDATE_SUPPLIER_BY_ID = '/suppliers'
export const GET_SUPPLIER_BY_ID = '/suppliers'
export const ADD_SUPPLIER = '/suppliers/store'
export const DELETE_SUPPLIER_BY_ID = '/suppliers'

//PurchaseOrders
export const GET_RESTAURANT_PURCHASE_ORDERS = '/purchaseOrders/restaurantId'
export const UPDATE_PURCHASE_ORDER_BY_ID = '/purchaseOrders'
export const GET_PURCHASE_ORDER_BY_ID = '/purchaseOrders'
export const ADD_PURCHASE_ORDER = '/purchaseOrders/store'
export const DELETE_PURCHASE_ORDER_BY_ID = '/purchaseOrders'

//Bookings
export const GET_RESTAURANT_BOOKINGS = '/bookings/restaurantId'
export const STORE_BOOKING = '/bookings/store'
export const UPDATE_BOOKING = '/bookings'
export const GET_BOOKING_BY_ID = '/bookings'
export const DELETE_BOOKING_BY_ID = '/bookings'

export const GET_ALL_VAT_RATES = '/vats/restaurantId'