import { all, fork } from "redux-saga/effects"
import LayoutSaga from "./layout/saga"
import adminSaga from './admin/saga'
import authSaga from './auth/login/saga'
import licenseSaga from './license/saga'
import customerSaga from "./customer/saga"
import orderSaga from "./order/saga"
import orderDetailsSaga from "./orderDetails/saga"
import restaurantSaga from "./restaurant/saga"
import statsSaga from "./stats/saga"
import smsSaga from "./sms/saga"
import menuSaga from './menu/saga'
import menuItemsSaga from './menuItems/saga'
import subMenuItemsSaga from './subMenuItems/saga'
import menuToppingsSaga from './menuToppings/saga'
import menuDressings from './menuDressings/saga'
import menuInstructions from './menuInstructions/saga'
import reportsSaga from './reports/saga'
import employeesSaga from './employee/saga'
import printersSaga from './printers/saga'
import cardTerminalsSaga from './cardTerminals/saga'
import giftCardsSaga from './giftCards/saga'
import kitchenDisplayScreens from './kitchenDisplayScreens/saga'
import suppliersSaga from './suppliers/saga'
import purchaseOrdersSaga from './purchaseOrders/saga'
import migrationSaga from './migration/saga'
import bookingsSaga from './bookings/saga'
import vatRateSaga from "./vatRates/saga"

export default function* rootSaga() {
  yield all([
    fork(LayoutSaga),
    fork(adminSaga),
    fork(authSaga),
    fork(licenseSaga),
    fork(customerSaga),
    fork(orderSaga),
    fork(orderDetailsSaga),
    fork(restaurantSaga),
    fork(statsSaga),
    fork(smsSaga),
    fork(menuSaga),
    fork(menuItemsSaga),
    fork(subMenuItemsSaga),
    fork(menuToppingsSaga),
    fork(menuDressings),
    fork(menuInstructions),
    fork(reportsSaga),
    fork(employeesSaga),
    fork(printersSaga),
    fork(cardTerminalsSaga),
    fork(giftCardsSaga),
    fork(kitchenDisplayScreens),
    fork(suppliersSaga),
    fork(purchaseOrdersSaga),
    fork(migrationSaga),
    fork(bookingsSaga),
    fork(vatRateSaga)
  ])
}
