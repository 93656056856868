import { call, put, takeEvery } from "redux-saga/effects";
import { PurchaseOrdersActionTypes } from "./actionTypes";
import { DeleteResponse, IResponse } from "../../interfaces/general";
import { deletePurchaseOrderSuccess, getRestaurantPurchaseOrdersSuccess, getPurchaseOrderByIdSuccess, purchaseOrderApiCallError, storePurchaseOrderSuccess, updatePurchaseOrderByIdSuccess } from "./actions";

import { addPurchaseOrderAsync, deletePurchaseOrderByIdAsync, getAllPurchaseOrdersAsync, getPurchaseOrderByIdAsync, updatePurchaseOrderByIdAsync } from "../../helpers/backend_helper";

function* getRestaurantPurchaseOrders({ payload: id}: any) {
  try {
    const response: IResponse = yield call(getAllPurchaseOrdersAsync, id);
    if(response.success) {
      yield put(getRestaurantPurchaseOrdersSuccess(response.result.sort((a:any, b:any) => a.id-b.id)));
    } 
  } catch (error) {
    console.log(error)
    yield put(purchaseOrderApiCallError(error))
  }
}

function* updatePurchaseOrder({ payload: {id, item, history} }: any) {
  try {
    const response: IResponse = yield call(updatePurchaseOrderByIdAsync, id, item);
    if(response.success) {
      yield put(updatePurchaseOrderByIdSuccess(response.result));
      if (history) {
        history.push('/stocks')
      }
    }
  } catch (error) {
    console.log(error)
    yield put(purchaseOrderApiCallError(error))
  }
}

function* addPurchaseOrder({ payload: menuDressing }: any) {
  try {
    const response: IResponse = yield call(addPurchaseOrderAsync, menuDressing);
    if(response.success) {
      yield put(storePurchaseOrderSuccess(response.result));
    }
  } catch (error) {
    console.log(error)
    yield put(purchaseOrderApiCallError(error))
  }
}

function* getPurchaseOrderById({ payload: id}: any) {
  try {
    const response: IResponse = yield call(getPurchaseOrderByIdAsync, id);
    if(response.success) {
      yield put(getPurchaseOrderByIdSuccess(response.result));
    }
  } catch (error) {
    console.log(error)
    yield put(purchaseOrderApiCallError(error))
  }
}

function* deletePurchaseOrder({ payload: id }: any) {
  try {
    const response: DeleteResponse = yield call(deletePurchaseOrderByIdAsync, id);
    if(response.success) {
      yield put(deletePurchaseOrderSuccess(id));
    }
  } catch (error) {
    console.log(error)
    yield put(purchaseOrderApiCallError(error))
  }
}

function* restaurantSaga() {
  yield takeEvery(PurchaseOrdersActionTypes.GET_RESTAURANT_PURCHASE_ORDERS, getRestaurantPurchaseOrders)
  yield takeEvery(PurchaseOrdersActionTypes.UPDATE_PURCHASE_ORDER_BY_ID, updatePurchaseOrder);
  yield takeEvery(PurchaseOrdersActionTypes.DELETE_PURCHASE_ORDER_BY_ID, deletePurchaseOrder);
  yield takeEvery(PurchaseOrdersActionTypes.ADD_PURCHASE_ORDER, addPurchaseOrder)
  yield takeEvery(PurchaseOrdersActionTypes.GET_PURCHASE_ORDER_BY_ID, getPurchaseOrderById);
}

export default restaurantSaga;


