import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Col, Container, Row, Input, FormGroup, Button } from "reactstrap";
import { withRouter } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { getAvgPrepTime, getCategoryMenuItems, getHourlySales, getRestaurantById, getAllRestaurantsByManager, getRestaurantMenuCategories, getSalesPerMenuCategories, getSalesPerMenuItems, getSalesPerPaymentTypes, getSalesPerSubMenuItems } from "src/store/actions";
import { getUserRestaurantId } from "src/helpers/backend_helper";
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    BarElement
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { convertSecondsToHMS } from "src/helpers/general";

ChartJS.register(
    Title,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    BarElement
);

const Reports = () => {
    const dispatch = useDispatch();
    const { singleRestaurant: restaurant, restaurants } = useSelector( (state: any) => state.restaurant);
    const [selectedRestId, setSelRestId] = useState(localStorage.selectedRestaurantId || '');
    const { 
        hourlySales,
        salesPerMenuCategories,
        salesPerMenuItems,
        salesPerSubMenuItems,
        salesPerPaymentTypes,
        avgPrepTime,
        loading
    } = useSelector( (state: any) => state.reports);
    const { categories: menuCategories, loading: loadingMenu } = useSelector( (state: any) => state.menu);
    const { items: menuItems, loading: loadingItems } = useSelector( (state: any) => state.menuItems);
    const [selectedMenuCategory, setSelectedMenuCategory] = useState<any>('');
    const [selectedMenuItem, setSelectedMenuItem] = useState<any>('');
    const [dateFrom, setDateFrom] = useState('');
    const [dateTo, setDateTo] = useState('');
    const [isHospitality, setHospitality] = useState(false);

    useEffect(() => {
        const userRestId = getUserRestaurantId()
        if (userRestId > 0) {
            setSelRestId(userRestId);
        } else if(userRestId == 0){
          dispatch(getAllRestaurantsByManager())
        }
      }, []);
  
    useEffect(() => {
        const dateNow = new Date();
        dispatch(getHourlySales(selectedRestId, dateNow.toISOString().substring(0, 10), ''));
        dispatch(getSalesPerMenuCategories(selectedRestId, dateNow.toISOString().substring(0, 10), ''));
        dispatch(getSalesPerPaymentTypes(selectedRestId, dateNow.toISOString().substring(0, 10), ''));
        dispatch(getRestaurantMenuCategories(selectedRestId))
        dispatch(getAvgPrepTime(selectedRestId, dateNow.toISOString().substring(0, 10), ''));
        if (selectedRestId !== '' && parseInt(selectedRestId) > 0) {
            localStorage.setItem("selectedRestaurantId", selectedRestId);
            dispatch(getRestaurantById(selectedRestId));
        }
    }, [selectedRestId])

    useEffect(() => {
        if (getUserRestaurantId() > 0 && restaurant && restaurant.id) {
            setHospitality(restaurant.RestaurantType === 0 || restaurant.RestaurantType === 2);
        }
    }, [restaurant])

    const onDateFilterApply = () => {
        dispatch(getHourlySales(selectedRestId, dateFrom, dateTo));
        dispatch(getSalesPerMenuCategories(selectedRestId, dateFrom, dateTo));
        dispatch(getSalesPerPaymentTypes(selectedRestId, dateFrom, dateTo));
        dispatch(getAvgPrepTime(selectedRestId, dateFrom, dateTo));
    }

    const onRestaurantChange = (event: any) => {
        setSelRestId(event.target.value)
      }
  
    return (
    <React.Fragment>
        <div className="page-content">
        <MetaTags>
            <title>Reports</title>
        </MetaTags>
        <Container fluid>
            <Breadcrumbs title="Reports" breadcrumbItem="Reports" />
            <Row>
                <Col sm="4"></Col>
                <Col sm="2">
                    <Input type="date" value={dateFrom} onChange={(e) => setDateFrom(e.target.value)} />
                </Col>
                <Col sm="2">
                    <Input type="date" value={dateTo} onChange={(e) => setDateTo(e.target.value)} />
                </Col>
                <Col sm="1">
                    <Button color="success" onClick={onDateFilterApply}>Apply</Button>
                </Col>
                <Col sm="2">
                {getUserRestaurantId() == 0 && (
                <Input name="restaurantId" onChange={onRestaurantChange} value={selectedRestId} type="select">
                    <option key="all" value="-1">Select restaurant</option>
                    {restaurants.map((restaurant: any) => (
                    <option key={restaurant.id} value={restaurant.id}>{restaurant.ShopName}</option>
                    ))}
                </Input>
                )}
            </Col>
            </Row>
            {isHospitality && (
                <Row>
                    <h5>Avg. Prep. Time: &nbsp; {convertSecondsToHMS(Math.floor(avgPrepTime))}</h5>
                </Row>
            )}
            <Row>
                <Col md="6">
                    <Bar
                        options={{
                            plugins: {
                                title: {
                                    display: true,
                                    text: 'Hourly sales'
                                }
                            }
                        }}
                        data={{
                            labels: hourlySales.map((d: any) => `${d.date} ${d.hour}:00`),
                            datasets: [{
                                label: 'Price Sum',
                                data: hourlySales.map((d: any) => d.priceSum),
                                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                            },{
                                label: 'No. of Order',
                                data: hourlySales.map((d: any) => d.salesCount),
                                backgroundColor: 'rgba(53, 162, 235, 0.5)',
                            }]
                        }}

                    />
                </Col>
                <Col md="6">
                    <Bar
                            options={{
                                plugins: {
                                    title: {
                                        display: true,
                                        text: 'Sales per payment types'
                                    }
                                }
                            }}
                            data={{
                                labels: salesPerPaymentTypes.map((d: any) => d.paymentType),
                                datasets: [{
                                    label: 'Price Sum',
                                    data: salesPerPaymentTypes.map((d: any) => d.priceSum),
                                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                                },{
                                    label: 'No. of Order',
                                    data: salesPerPaymentTypes.map((d: any) => d.salesCount),
                                    backgroundColor: 'rgba(53, 162, 235, 0.5)',
                                }]
                            }}

                        />
                </Col>
            </Row>
            <Row className="mt-4">
                <Col md="4">
                    <div className="flex flex-row ml-4">
                        <label>Select menu category to check sales per menu items of : &nbsp;</label>
                        <Input value={selectedMenuCategory} onChange={(e) => {
                            setSelectedMenuCategory(e.target.value);
                            if (e.target.value === '') {
                                setSelectedMenuItem('');
                            } else {
                                setSelectedMenuItem('');
                                dispatch(getCategoryMenuItems(e.target.value));
                                dispatch(getSalesPerMenuItems(getUserRestaurantId(), e.target.value, dateFrom, dateTo));
                            }
                        }} type="select">
                            <option value=''>---</option>
                            {menuCategories.map(((menuCategory: any) => (
                                <option value={menuCategory.id}>{menuCategory.Name }</option>
                            )))}
                        </Input>
                    </div>
                </Col>
                {selectedMenuCategory && (                    
                    <Col md="4">
                        <div className="flex flex-row ml-4">
                            <label>Select menu item to check sales per sub menu items of : &nbsp;</label>
                            <Input value={selectedMenuItem} onChange={(e) => {
                                setSelectedMenuItem(e.target.value);
                                dispatch(getSalesPerSubMenuItems(getUserRestaurantId(), e.target.value, dateFrom, dateTo));
                            }} type="select">
                                <option value=''>---</option>
                                {menuItems.map(((menuItem: any) => (
                                    <option value={menuItem.id}>{menuItem.Name }</option>
                                )))}
                            </Input>
                        </div>
                    </Col>
                )}
            </Row>
            <Row>
                <Col md="4">
                    <Bar
                        options={{
                            plugins: {
                                title: {
                                    display: true,
                                    text: `Sales per ${isHospitality ? 'menu categories' : 'category'}`
                                }
                            }
                        }}
                        data={{
                            labels: salesPerMenuCategories.map((d: any) => d.Name),
                            datasets: [{
                                label: 'Price Sum',
                                data: salesPerMenuCategories.map((d: any) => d.priceSum),
                                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                            },{
                                label: 'No. of Order',
                                data: salesPerMenuCategories.map((d: any) => d.salesCount),
                                backgroundColor: 'rgba(53, 162, 235, 0.5)',
                            }]
                        }}

                    />
                </Col>
                {selectedMenuCategory && (
                    <Col md="4">
                        <Bar
                            options={{
                                plugins: {
                                    title: {
                                        display: true,
                                        text: 'Sales per menu items of ' + (menuCategories.filter((m: any) => ""+m.id === selectedMenuCategory)[0].Name)
                                    }
                                }
                            }}
                            data={{
                                labels: salesPerMenuItems.map((d: any) => d.Name),
                                datasets: [{
                                    label: 'Price Sum',
                                    data: salesPerMenuItems.map((d: any) => d.priceSum),
                                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                                },{
                                    label: 'No. of Order',
                                    data: salesPerMenuItems.map((d: any) => d.salesCount),
                                    backgroundColor: 'rgba(53, 162, 235, 0.5)',
                                }]
                            }}

                        />
                    </Col>
                )}
                {selectedMenuItem && (
                    <Col md="4">
                        <Bar
                            options={{
                                plugins: {
                                    title: {
                                        display: true,
                                        text: 'Sales per sub menu items of ' + (menuItems.filter((m: any) => ""+m.id === selectedMenuItem)[0].Name)
                                    }
                                }
                            }}
                            data={{
                                labels: salesPerSubMenuItems.map((d: any) => d.Name),
                                datasets: [{
                                    label: 'Price Sum',
                                    data: salesPerSubMenuItems.map((d: any) => d.priceSum),
                                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                                },{
                                    label: 'No. of Order',
                                    data: salesPerSubMenuItems.map((d: any) => d.salesCount),
                                    backgroundColor: 'rgba(53, 162, 235, 0.5)',
                                }]
                            }}

                        />
                    </Col>
                )}
            </Row>
        </Container>
        </div>
    </React.Fragment>
    );
};

export default withRouter(Reports);
