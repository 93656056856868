import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { withRouter } from "react-router-dom";
import ReactDrawer from "react-drawer";
import { Col, Row, Label } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { storeRestaurant } from "../../store/actions";
import { useSelector, useDispatch } from "react-redux";
import { SpinnerCircular } from "spinners-react";
import Select from 'react-select';

const appTypeOptions = [
  { value: 'EposAnytime', label: 'EposAnytime' },
  { value: 'OnePlusTwo', label: 'OnePlusTwo' },
];

const AddRestaurant = (props: any) => {
  const dispatch = useDispatch();
  const { success, loading } = useSelector((state: any) => state.restaurant);
  const [appType, setAppType] = useState<any>(null)

  useEffect(() => {
    if (success) {
      props.onDrawerClose(false);
    }
  }, [success]);

  const handleAddAdmin = (values: Record<string, any>) => {  
    if (appType) {  
      values.AppType = appType.value;
    }  
    dispatch(storeRestaurant(values));
  };

  const handleChangeAppType = (value: any) => {
    setAppType(value)
  }

  return (
    <React.Fragment>
      <div className="add-company">
        <MetaTags>
          <title>Add New Admin</title>
        </MetaTags>
      </div>
      <ReactDrawer
        open={props.open}
        position={"right"}
        onClose={props.onDrawerClose}
      >
        <div className="add-admin-form" style={{ padding: 20 }}>
          <AvForm
            onValidSubmit={(e: any, values: any) => {
              handleAddAdmin(values);
            }}
          >
            <div className="add-company-form-cotainer">
              <Row>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="Authkey"
                      label="Authkey"
                      type="text"
                      errorMessage="Authkey"
                      validate={{
                        required: { value: true },
                      }}
                      value={""}
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="ShopName"
                      label="ShopName"
                      type="text"
                      errorMessage="ShopName"
                      validate={{
                        required: { value: true },
                      }}
                      value={""}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="BuildingName"
                      label="BuildingName"
                      type="text"
                      errorMessage="BuildingName"
                      validate={{
                        required: { value: true },
                      }}
                      value={""}
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="Street"
                      label="Street"
                      type="text"
                      errorMessage="Street"
                      validate={{
                        required: { value: true },
                      }}
                      value={""}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="City"
                      label="City"
                      type="text"
                      errorMessage="Invalid City"
                      validate={{
                        required: { value: true },
                      }}
                      value={""}
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="Country"
                      label="Country"
                      type="text"
                      errorMessage="Invalid Country"
                      validate={{
                        required: { value: true },
                      }}
                      value={""}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="Tel1"
                      label="Tel1"
                      type="text"
                      errorMessage="Invalid Tel1"
                      validate={{
                        required: { value: true },
                      }}
                      value={""}
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="Tel2"
                      label="Tel2"
                      type="text"
                      errorMessage="Invalid Tel2"
                      validate={{
                        required: { value: true },
                      }}
                      value={""}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="email"
                      label="email"
                      type="text"
                      errorMessage="Invalid email"
                      validate={{
                        required: { value: true },
                      }}
                      value={""}
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="WebURL"
                      label="WebURL"
                      type="text"
                      errorMessage="Invalid WebURL"
                      validate={{
                        required: { value: true },
                      }}
                      value={""}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <div className="mb-3">
                    <Label>App Type</Label>
                    <Select
                      options={appTypeOptions}
                      value={appType}
                      onChange={handleChangeAppType}
                    ></Select>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="text-end">
                    <button type="submit" className="btn btn-success save-user update-btn" disabled={loading}>
                      {loading ? (
                        <SpinnerCircular
                          size={30}
                          thickness={150}
                          speed={150}
                          color="#36ad47"
                          secondaryColor="rgba(0, 0, 0, 0.44)"
                        />
                      ) : (
                        <>Save</>
                      )}
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
          </AvForm>
        </div>
      </ReactDrawer>
    </React.Fragment>
  );
};

export default withRouter(AddRestaurant);
