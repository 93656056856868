import { call, put, takeEvery } from "redux-saga/effects";
import { SubMenuItemsActionTypes } from "./actionTypes";
import { DeleteResponse, IResponse } from "../../interfaces/general";
import {getAllSubMenuItemsSuccess, storeSubMenuItemSuccess, subMenuItemApiCallError, deleteSubMenuItemSuccess, updateSubMenuItemByIdSuccess, getMenuItemSubMenuItemsSuccess, getSubMenuItemByIdSuccess} from "./actions";

import { addSubMenuItemAsync, deleteSubMenuItemByIdAsync, getAllSubMenuItemsAsync, getMenuItemSubMenuItemsAsync, getSubMenuItemByIdAsnyc, updateSubMenuItemByIdAsync } from "../../helpers/backend_helper";

function* getAllSubMenuItems() {
  try {
    const response: IResponse = yield call(getAllSubMenuItemsAsync);
    if(response.success) {
      yield put(getAllSubMenuItemsSuccess(response.result.sort((a:any, b:any) => b.id-a.id)));
    } 
  } catch (error) {
    console.log(error)
    yield put(subMenuItemApiCallError(error))
  }
}

function* getMenuItemSubMenuItems({ payload: id}: any) {
  try {
    const response: IResponse = yield call(getMenuItemSubMenuItemsAsync, id);
    if(response.success) {
      yield put(getMenuItemSubMenuItemsSuccess(response.result));
    }
  } catch (error) {
    console.log(error)
    yield put(subMenuItemApiCallError(error))
  }
}

function* updateSubMenuItem({ payload: {id, item, history} }: any) {
  try {
    const response: IResponse = yield call(updateSubMenuItemByIdAsync, id, item);
    if(response.success) {
      yield put(updateSubMenuItemByIdSuccess(response.result));
      if (history) {
        history.push('/menu/categories')
      }
    }
  } catch (error) {
    console.log(error)
    yield put(subMenuItemApiCallError(error))
  }
}

function* addSubMenuItem({ payload: menuItem }: any) {
  try {
    const response: IResponse = yield call(addSubMenuItemAsync, menuItem);
    if(response.success) {
      yield put(storeSubMenuItemSuccess(response.result));
    }
  } catch (error) {
    console.log(error)
    yield put(subMenuItemApiCallError(error))
  }
}

function* getSubMenuItemById({ payload: id}: any) {
  try {
    console.log("me id " + JSON.stringify(id));
    const response: IResponse = yield call(getSubMenuItemByIdAsnyc, id);
    if(response.success) {
      yield put(getSubMenuItemByIdSuccess(response.result));
    }
  } catch (error) {
    console.log(error)
    yield put(subMenuItemApiCallError(error))
  }
}

function* deleteSubMenuItem({ payload: id }: any) {
  try {
    const response: DeleteResponse = yield call(deleteSubMenuItemByIdAsync, id);
    if(response.success) {
      yield put(deleteSubMenuItemSuccess(id));
    }
  } catch (error) {
    console.log(error)
    yield put(subMenuItemApiCallError(error))
  }
}

function* restaurantSaga() {
  yield takeEvery(SubMenuItemsActionTypes.GET_ALL_SUBMENUITEMS, getAllSubMenuItems)
  yield takeEvery(SubMenuItemsActionTypes.GET_MENUITEM_SUBMENUITEMS, getMenuItemSubMenuItems)
  yield takeEvery(SubMenuItemsActionTypes.UPDATE_SUBMENUITEM_BY_ID, updateSubMenuItem);
  yield takeEvery(SubMenuItemsActionTypes.DELETE_SUBMENUITEM_BY_ID, deleteSubMenuItem);
  yield takeEvery(SubMenuItemsActionTypes.ADD_SUBMENUITEM, addSubMenuItem)
  yield takeEvery(SubMenuItemsActionTypes.GET_SUBMENUITEM_BY_ID, getSubMenuItemById);
}

export default restaurantSaga;


