import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Card, CardBody, CardGroup, CardHeader, CardText, CardTitle, Col, Container, Progress, Row, Input } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { getAllStats, getRestaurantById, getAllRestaurantsByManager } from "../../store/actions";
import { getUserRestaurantId } from "src/helpers/backend_helper";

const fields = [{
  key: "numberOfCustomers",
  label: "All Customers",
}, {
  key: "totalSale",
  label: "Today Total Sale",
}, {
  key: "cashOrderProcessed",
  label: "Cash Order Processed",
}, {
  key: "cardOrderProcessed",
  label: "Card Order Processed",
}, {
  key: "numberOfTodayOrders",
  label: "Number of Today Orders",
}, {
  key: "avgPrepTime",
  label: "Avg. Prep. Time for Orders",
  url: "/orders"
}]

const progs = [{
  key: "delivery",
  label: "Delivery",
}, {
  key: "collection",
  label: "Collection"
}, {
  key: "eatIn",
  label: "Eat-In"
}, {
  key: "takeaway",
  label: "Takeaway"
}, {
  key: "website",
  label: "Website"
}]


const Dashboard = () => {
  
  const fieldRows = [[fields[0], fields[1], fields[5]], [fields[2], fields[3], fields[4]]];
  const rows = window.screen.width < 380 ? fieldRows.reduce((r1, r2) => r1.concat(r2), []).map(r => [r]) : fieldRows
  const dispatch = useDispatch();
  const { stats, loading } = useSelector( (state: any) => state.stats );
  const {singleRestaurant: restaurant, restaurants} = useSelector((state: any) => state.restaurant);
  const [selectedRestId, setSelRestId] = useState(localStorage.selectedRestaurantId || '');
  const [isHospitality, setHospitality] = useState(false);

  useEffect(() => {
    const userRestId = getUserRestaurantId()
    if (userRestId > 0) {
      setSelRestId(userRestId);
    } else if(userRestId == -1){
      setSelRestId("0");
    }else if(userRestId == 0){
      dispatch(getAllRestaurantsByManager())
    }
  }, []);

  useEffect(() => {
    dispatch(getAllStats(selectedRestId));
    if (selectedRestId > 0) {
      localStorage.setItem("selectedRestaurantId", selectedRestId);
      dispatch(getRestaurantById(selectedRestId));
    }
  }, [selectedRestId]);

  useEffect(() => {
    if (selectedRestId > 0 && restaurant && restaurant.id) {
      setHospitality(restaurant.RestaurantType === 0 || restaurant.RestaurantType === 2);
    }
  }, [restaurant])

  const handleItemClick = (item: any) => {
    if (item.url) {
      window.location.href = item.url;
    }
  }

  const onRestaurantChange = (event: any) => {
    setSelRestId(event.target.value)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Dashboard | Minia - React Admin & Dashboard Template</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Dashboard" breadcrumbItem="Dashboard" />
          
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <Row>
                    <Col sm="8">
                      <h2>Statistics </h2>
                    </Col>
                    <Col sm="2">
                      {getUserRestaurantId() == 0 && (
                        <Input name="restaurantId" onChange={onRestaurantChange} value={selectedRestId} type="select">
                          <option key="all" value="-1">Select restaurant</option>
                          {restaurants.map((restaurant: any) => (
                            <option key={restaurant.id} value={restaurant.id}>{restaurant.ShopName}</option>
                          ))}
                        </Input>
                      )}
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>                  
                  {rows.map((row) => (
                    <Row className="mb-4">
                      {row.map((field) => (
                        <>
                          {(isHospitality || field.key != 'avgPrepTime') && (
                            <Col key={field.key} onClick={() => handleItemClick(field)} style={field.url ? {cursor: "pointer"} : {}}>
                              <div className="stats-card bg-primary">
                                <h4 className="text-white">{field.label}</h4>
                                <p className="mt-4 mb-0 text-white">
                                  {loading ? "Loading..." : stats[field.key]}
                                </p>
                              </div>
                            </Col>
                          )}
                        </>
                      ))}
                    </Row>
                  ))}
                  
                  <div className="stats-card bg-gray mt-4">
                    {progs.map((prog) => (
                      <div className="mb-2">
                        <h5>{prog.label}</h5>
                        <Progress 
                          value={loading ? 0 : stats && stats.progs ? stats.progs[prog.key]: 0}
                        />
                      </div>
                    ))}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
