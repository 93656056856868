import { VatRatesTypes } from "./actionTypes";

export const getAllVatRates= (restaurantId: any) => ({
  type: VatRatesTypes. GET_ALL_VATRATES,
  payload: restaurantId
});

export const getAllVatRatesSuccess = (vat_rates: any) => ({
  type: VatRatesTypes.GET_ALL_VATRATES_SUCCESS,
  payload: vat_rates,
});





